import { useSearchParams } from "@remix-run/react";
import { NavigateOptions, useLocation } from "react-router-dom";

export const useParamUpdater = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { pathname } = useLocation();

  const onChangeSearchParam = (
    key: string,
    value?: string | number | boolean | Date,
    options: NavigateOptions = {
      preventScrollReset: false,
    }
  ) => {
    setSearchParams({ ...params, [key]: value?.toString() || "" }, options);
  };
  const onChangeSearchParams = (
    keys: {
      [key: string]: string | number | boolean | Date;
    },
    options: NavigateOptions = {
      preventScrollReset: false,
    }
  ) => {
    const scrollPosition = window.scrollY;
    localStorage.setItem("persistentScroll", scrollPosition.toString());
    const newParams = {
      ...params,
      ...Object.fromEntries(
        Object.entries(keys).map(([key, value]) => [
          key,
          value?.toString() || "",
        ])
      ),
    };
    setSearchParams(newParams, { replace: false, ...options });
  };

  // useEffect(() => {
  //   const persistentScroll = localStorage.getItem("persistentScroll");

  //   if (!persistentScroll) return;

  //   window.scrollTo({ top: Number(persistentScroll) });

  //   if (Number(persistentScroll) === window.scrollY) {
  //     localStorage.removeItem("persistentScroll");
  //   }
  // }, [searchParams]);
  // useEffect(() => {
  //   const persistentScroll = localStorage.getItem("persistentScroll");

  //   if (!persistentScroll) return;

  //   if (Number(persistentScroll) === window.scrollY) {
  //     localStorage.removeItem("persistentScroll");
  //   }
  // }, [pathname]);

  const getSearchParamValue = (key: string) => {
    return searchParams.get(key);
  };

  const deleteSearchParam = (key: string) => {
    searchParams.delete(key);
  };

  const resetSearchParams = (
    keys?: { [key: string]: string },
    options: NavigateOptions = {
      preventScrollReset: false,
    }
  ) => {
    setSearchParams(keys || {}, options);
  };

  return {
    onChangeSearchParam,
    searchParams,
    params,
    getSearchParamValue,
    deleteSearchParam,
    onChangeSearchParams,
    resetSearchParams,
  };
};
